import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    isChangeList: false,
    isFirstEntry: true, // 是否首次进入，判断从绑定页面进入首页是否在进行判断跳转
    entryGate: null,// 实名登记过返回的二维码等数据
    url: '' // 记录第一次的url
  },
  mutations: {
    saveToken(state, token) {
      state.token = token;
    },
    changeList(state, is) {
      state.isChangeList = is;
    },
    changeFirstEntry(state, is) {
      state.isFirstEntry = is;
    },
    changeEntryGate(state, data) {
      state.entryGate = data;
    },
    setUrl(state, url) {
      state.url = url
    }
  },
  actions: {
  },
  modules: {
  }
})
