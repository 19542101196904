import {
  Toast
} from "vant";
import router from '../router/index';
const tool = {
  // 判断是否有缓存和过期
  async isLoseCache(key, api) {
    // key:缓存名称
    // api:无缓存时调接口
    let historyData = window.localStorage.getItem(key) || null;
    historyData = JSON.parse(historyData);
    let apiData;
    if (historyData) {
      // 有缓存，判断过期
      let oldTime = historyData.oldTime;
      let curTime = new Date().getTime();
      let gap = 1000 * 60 * 60 * 24; // 1天
      if (curTime - oldTime > gap) {
        // 过期
        window.localStorage.removeItem(key);
        // 调接口方法
        apiData = await this.getApiData(key, api);
      } else {
        apiData = await this.getApiData(key, api);
      }
    } else {
      // 无缓存
      apiData = await this.getApiData(key, api);
    }
    return apiData;
  },
  async getApiData(key, api) {
    // 获取数据
    let apiData = await api;
    let data = {
      data: apiData.data.result,
      oldTime: new Date().getTime()
    };
    window.localStorage.setItem(key, JSON.stringify(data));
    return apiData.data.result;
  },
  /** 
   * 跳转登录页
   * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
   */
  toLogin() {
    //获取当前路径
    let curPath = router.history.pending ? router.history.pending.fullPath.slice(1) : router.history.current.fullPath.slice(1);
    let href = process.env.NODE_ENV === 'development' ? 'http://192.168.8.245:61127/api/Account/login?redirectUrl=' + curPath : '/api/Account/login?redirectUrl=' + escape(curPath)
    window.location.replace(href);
  },
  // 严格的身份证校验
  isCardID(sId) {
    sId = sId || '';
    if (!/(^\d{15}$)|(^\d{17}(\d|X|x)$)/.test(sId)) {
      Toast('你输入的身份证长度或格式错误')
      return false
    }
    //城市
    var aCity = {
      11: "北京",
      12: "天津",
      13: "河北",
      14: "山西",
      15: "内蒙古",
      21: "辽宁",
      22: "吉林",
      23: "黑龙江",
      31: "上海",
      32: "江苏",
      33: "浙江",
      34: "安徽",
      35: "福建",
      36: "江西",
      37: "山东",
      41: "河南",
      42: "湖北",
      43: "湖南",
      44: "广东",
      45: "广西",
      46: "海南",
      50: "重庆",
      51: "四川",
      52: "贵州",
      53: "云南",
      54: "西藏",
      61: "陕西",
      62: "甘肃",
      63: "青海",
      64: "宁夏",
      65: "新疆",
      71: "台湾",
      81: "香港",
      82: "澳门",
      91: "国外"
    };
    if (!aCity[parseInt(sId.substr(0, 2))]) {
      Toast('你的身份证地区非法')
      return false
    }

    // 出生日期
    var sBirthday = (sId.substr(6, 4) + "-" + Number(sId.substr(10, 2)) + "-" + Number(sId.substr(12, 2))).replace(/-/g, "/"),
      d = new Date(sBirthday)
    if (sBirthday != (d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate())) {
      Toast('身份证上的出生日期非法')
      return false
    }

    // 号码
    var sum = 0,
      weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2],
      codes = "10X98765432"
    for (var i = 0; i < sId.length - 1; i++) {
      sum += sId[i] * weights[i];
    }
    var last = codes[sum % 11]; //计算出来的最后一位身份证号码
    if (sId[sId.length - 1] != last) {
      Toast('你输入的身份证号非法')
      return false
    }

    return true
  },
  // 港澳通行证
  checkHkongMacao(value) {
    value = value || '';
    var tel = /^[HMhm]{1}([0-9]{8})$/;
    if (tel.test(value)) {
      return true;
    } else {
      return false;
    };
  },
  //护照规则，5-17位，由数字和字母组成，可以全是字母
  checkPassport(value) {
    value = value || '';
    var tel2 = /^[a-zA-Z]{5,17}$/;
    var tel = /^[a-zA-Z0-9]{5,17}$/;
    if ((tel.test(value)) || tel2.test(value)) {
      return true;
    } else {
      return false;
    }
  },
  //台胞证
  checkTaiwCont(value) {
    value = value || '';
    var tel1 = /^[0-9]{8}$/;
    if (tel1.test(value)) {
      return true;
    } else {
      return false;
    };
  },
  /*所有证件严格校验*/
  idCardNo(value) {
    value = value || '';
    if (this.isCardID(value) || this.checkHkongMacao(value) || this.checkPassport(value) || this.checkTaiwCont(value)) {
      Toast('证件通过')
      return true;
    } else {
      Toast('请输入正确的考生证件号码')
      return false;
    }
  },
  /*证件有值校验*/
  idCardNo2(value) {
    value = value || '';
    if (value.length > 0) {
      return true;
    } else {
      Toast('请输入正确的证件号码');
      return false;
    }
  },
  //手机号校验
  isPhone(phone) {
    phone = phone || '';
    if (/^1[3|4|5|6|7|8|9][0-9]{9}$/.test(phone)) {
      return true;
    } else {
      Toast('请输入正确的手机号码');
      return false
    }
  },
  //验证码校验
  isCode(value) {
    value = value || '';
    if (value.length > 0) {
      return true;
    } else {
      Toast('请输入验证码');
      return false;
    }
  },
  //姓名校验
  isName(value) {
    value = value || '';
    if (value.length > 0) {
      return true;
    } else {
      Toast('请输入姓名');
      return false;
    }
  },
  //考试号等
  uniqueNo(value, desc) {
    value = value || '';
    if (value.length > 0) {
      return true;
    } else {
      Toast(`请输入对应的${desc}`);
      return false;
    }
  },
  resizeUrl() {
    //处理剪切url id
    let url = window.location.href;
    let obj = {};
    let reg = /[?&][^?&]+=[^?&]+/g;
    let arr = url.match(reg); // return ["?id=123456","&a=b"]
    if (arr) {
      arr.forEach(item => {
        let tempArr = item.substring(1).split("=");
        let key = tempArr[0];
        let val = tempArr[1];
        obj[key] = decodeURIComponent(val);
      });
    }
    return obj;
  }
}
export default tool;