import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import tool from '../assets/tool'
import axios from '../api/index'
import { Toast } from 'vant'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home/Home'),
    meta: {
      // keepAlive: true
    }
  },
  {
    //绑定页面
    path: '/verification',
    name: 'Verification',
    component: () => import('../views/Verification/Verification')
  },
  {
    //查询页面
    path: '/search',
    name: 'Search',
    component: () => import('../views/Search/Search'),
  },
  {
    //自考管理查询页面
    path: '/zikaosearch',
    name: 'ZiKaoSearch',
    component: () => import('../views/Search/ZiKaoSearch'),
  },
  {
    //签字确认
    path: '/signature',
    name: 'Signature',
    component: () => import('../views/Signature/Signature')
  },
  {
    //资讯列表
    path: '/list',
    name: 'List',
    component: () => import('../views/News/List'),
    meta: {
      keepAlive: true
    }
  },
  {
    //资讯详情
    path: '/detail',
    name: 'Detail',
    component: () => import('../views/News/Detail')
  },
  {
    //订阅主页面
    path: '/subscribe',
    name: 'Subscribe',
    component: () => import('../views/Subscribe/Subscribe')
  },
  {
    //宣传页面
    path: '/propaganda',
    name: 'Propaganda',
    component: () => import('../views/Propaganda/Propaganda')
  },
  {
    //实名登记
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register/Register')
  },
  // 实名登记详情
  {
    path: '/registerdetail',
    name: 'registerdetail',
    component: () => import('../views/RegisterDetail/RegisterDetail')
  },
  // 核验实名登记
  {
    path: '/aucode',
    name: 'Aucode',
    component: () => import('../views/AuQrCode/AuQrCode')
  },
  //其他
  //网站二维码扫描获取验证码
  {
    path: '/qrcode',
    name: 'QrCode',
    component: () => import('../views/QrCode/QrCode')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})
// ... other code

router.beforeEach(async (to, from, next) => {
  // 处理jssdk签名,兼容history模式
  if (!store.state.url) {
    store.commit('setUrl', document.URL)
  }
  //判断是否获取过token
  let token =
    store.state.token || window.sessionStorage.getItem("token");
  //无token
  if (!token) {
    let code = tool.resizeUrl().code;
    if (!code) {
      //重定向
      tool.toLogin();
    } else {
      let res = await axios.getToken(code);
      if (res.data.result) {
        token = res.data.result.token;
      } else {
        Toast("请重新打开页面");
      }
      //保存token
      store.commit("saveToken", token);
      window.sessionStorage.setItem("token", token);
      // 保存是否订阅公众号
      window.sessionStorage.setItem(
        "isSubscribe",
        res.data.result.isSubscribe
      );
      //首页重定向
      let reg = /state=(.+)\/?/;
      let state = window.location.search.match(reg)
        ? window.location.search.match(reg)[1]
        : "home";
      state = unescape(state);
      window.location.replace(unescape(state));
    }
  }
  next()
})

export default router
