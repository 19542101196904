<template>
  <!-- 首页、订阅跳转icon -->
  <div class="jumpIcon">
    <router-link v-if="isShowSubscribe" :to="{path:'/list'}" class="jumpDy"><span>我的</span><span>资讯</span></router-link>
    <router-link v-if="isShowSearch" :to="{path:'/home'}" class="jumpDy"><span>我的</span><span>考试</span></router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pathName: ""
    };
  },
  watch: {
    $route(val, oldval) {
      this.pathName = val.name;
    }
  },
  computed: {
    isShowSearch() {
      if (
        this.pathName === "List" ||
        this.pathName === "Detail" ||
        this.pathName === "Search"
      ) {
        return true;
      } else {
        return false;
      }
    },
    isShowSubscribe() {
      if (
        this.pathName === "Home" ||
        this.pathName === "Search" ||
        this.pathName === "Detail"
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style>
</style>