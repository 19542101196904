import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import api from './api/index'//导入api接口

import '../public/css/phone-reset.css'// 重置样式
import '../public/css/base.css'// 重置样式

Vue.config.productionTip = false
Vue.prototype.$api = api;//将api挂载在VUE原型上

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
