<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
    <TheJumpIcon />
  </div>
</template>
<script>
import TheJumpIcon from "./components/TheJumpIcon/TheJumpIcon";
export default {
  components: {
    TheJumpIcon
  }
};
</script>
<style>
</style>
