import axios from './axios'; // 导入http中创建的axios实例
const base = './api';

const api = {
    // 获取token    
    getToken(code) {
        return axios.get(`${base}/account/token?code=${code}`);
    },
    // 获取已绑定的卡片
    getAccountInfo() {
        return axios.get(`${base}/account/cards`);
    },
    // 获取可绑定的卡片
    getAddCardList() {
        return axios.get(`${base}/examCategory`);
    },
    //获取首页新闻资讯
    getHomeNewsList(params) {
        return axios.post(`${base}/content/list`, params);
    },
    /*
     绑定页面
     */
    //动态获取唯一列
    getBindFieldInfo(examid) {
        return axios.get(`${base}/examCategory/${examid}/fieldInfo`);
    },
    //发送验证码
    sendSms(phone, f) {
        return axios.get(`${base}/account/sms?phone=${phone}&f=${f}`);
    },
    //绑定卡片
    bindCard(params) {
        return axios.post(`${base}/account/bind`, params);
    },

    //绑定卡片，自考二次绑定
    bindSecondaryCard(params) {
        return axios.post(`${base}/account/bindSecondary`, params);
    },
    //自考考试 卡片详情一级目录
    getZiKaoDetail(id) {
        return axios.get(`${base}/account/cardDetailZiKao?examCategoryId=${id}`);
    },
    /*
     查询页面
     */
    //获取卡片详情
    getCardDetail(bindId) {
        return axios.get(`${base}/account/cardDetail?bindId=${bindId}`);
    },
    //解绑
    unBindeCard(id) {
        return axios.post(`${base}/account/unBind?id=${id}`);
    },

    //解绑
    unPostCheck() {
        return axios.post(`${base}/account/uncomplete`);
    },
    //自考更新
    modifyZK(id) {
        return axios.put(`${base}/account/bindAgain?bindId=${id}`);
    },
    //查询项目首次点击小红点
    clickItemDteail(params) {
        return axios.post(`${base}/account/clickItemDteail`, params);
    },
    //第三方查询
    goThird(id, bindId) {
        return axios.post(`${base}/account/${id}/searchDetail?bindId=${bindId}`);
    },
    //获取vr考点
    getVrList(flag = 'KSY005') {
        return axios.get(`${base}/account/${flag}/dictItems`);
    },
    // 获取考场学校信息
    getExamSchool(bindId, flag = 'KSY006') {
        return axios.get(`${base}/account/${bindId}/thridApiJsonResult?dictNo=${flag}`);
    },


    /*
    新闻资讯
    */
    // 获取标签列表和已订阅
    getLabelList() {
        return axios.get(`${base}/examCategory/contentTypes`);
    },
    //编辑完成我的订阅标签
    editSubscribe(parmas) {
        return axios.post(`${base}/examCategory/subscribe`, parmas);
    },
    //获取某个标签下所有资讯
    getLabelContents(labelId, index) {
        return axios.get(`${base}/examCategory/${labelId}/contents?pageIndex=${index}&PageSize=10`);
    },
    //获取资讯详情
    getLabelContent(labelId) {
        return axios.get(`${base}/content/${labelId}`);
    },


    /*
    实名登记
    */
    //获取当天是否登记过
    getChecked() {
        return axios.post(`${base}/account/entryGate`);
    },
    // 排队登记提交
    submitPaiDui(data) {
        return axios.post(`${base}/account/submitPaiDui/${data.selectValue}`, data);
    },
    // 获取业务类型
    getExamCategory() {
        return axios.get(`${base}/examCategory/business`);
    },
    //根据考试类别获取身份信息 
    getInfoForType(examCategoryId) {
        return axios.get(`${base}/account/paiDui?examCategoryId=${examCategoryId}`);
    },
    // 获取登记详情
    getGateLog(id) {
        return axios.get(`${base}/account/gateLog/${id}`);
    },
    //实名登记
    postCheck(params) {
        return axios.post(`${base}/account/complete`, params);
    },
    //获取排队登记跳转链接或者内容
    getFlow(id) {
        return axios.get(`${base}/account/paiDui?examCategoryId=${id}`)
    },
    //实名、排队登记身份证识别
    postCardId(params) {
        return axios.post(`${base}/account/ocr`, params);
    },
    // 获取weixin-js-sdk参数
    getWxConfig(url) {
        return axios.get(`${base}/account/jspackage?url=${url}`);
    },
    // 核验登记二维码
    checkAuCode(id) {
        return axios.post(`${base}/account/${id}/check`);
    },
    // 其他接口…………
    getQrCode() {
        return axios.get(`${base}/Account/qrcode`);
    },
    submitQrCode(code) {
        return axios.post(`${base}/Account/qrcode?${code}`);
    }
};
export default api;